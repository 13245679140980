import React from 'react';

import AppRoutes from "./routes/AppRoutes"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
    return (
        <AppRoutes />
    );
}

export default App;
